export function buildPageMeta(meta) {
  return {
    title: meta.title,
    meta: [
      {
        name: 'title',
        content: meta.title,
      },
      {
        name: 'description',
        content: meta.description,
      },
      {
        property: 'og:url',
        content: meta.url,
      },
      {
        property: 'og:title',
        content: meta.title,
      },
      {
        property: 'og:description',
        content: meta.description,
      },
      {
        property: 'og:image',
        content: meta.image,
      },
      {
        property: 'twitter:url',
        content: meta.url,
      },
      {
        property: 'twitter:title',
        content: meta.title,
      },
      {
        property: 'twitter:description',
        content: meta.description,
      },
      {
        property: 'twitter:image',
        content: meta.image,
      },
    ],
  };
}
