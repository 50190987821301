<template>
  <div>
    <div class="main-page">
      <div class="main-page-info">
        <router-link to="/">
          <img src="img/HP-Main-Logo.svg" alt="" />
        </router-link>
        <p style="margin-bottom: 2rem">
          We collect and store information from our users in multiple ways
          during usage of the HelixPay Platform. We collect information directly
          when Users (“Users” or “You”) and Customers use the HelixPay Platform
          and we collect information indirectly depending on usage of the
          HelixPay Platform.
        </p>

        <p style="margin-bottom: 2rem">
          Here are some examples of when we collect information from users;
          however this list is not exhaustive and this Privacy Policy applies to
          all use of the HelixPay Platform:
        </p>

        <p style="width: 90%; margin: auto; text-align: left">
          • When you register for an account
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          • When you update your profile information and HelixPay storefront
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          • When your Customers create a new subscription
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          • When you and your Customers navigate through the Platform
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          • When your Customers make a payment for their subscription with you
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          • When we send subscription payment reminders, messages and other
          notifications to your Customers on your behalf
        </p>
        <p
          style="
            width: 90%;
            margin: auto;
            text-align: left;
            margin-bottom: 2rem;
          "
        >
          • When you receive fund transfers from HelixPay
        </p>

        <p style="margin-bottom: 2rem">
          The information you provide us may include, but is not limited to:
          name, email address, phone number, work address, government ID
          information, official business registration information, photographs
          of yourself, photographs of your business, photographs of your
          business documents, photographs of your government ID, payment
          information including bank account details, educational
          qualifications, and professional experiences and references. This list
          is not exhaustive. You are not required to submit any information, but
          some features and/or areas of our Platform may not be accessible
          without certain information being provided.
        </p>

        <h2>Information from third parties:</h2>
        <p style="margin-bottom: 2rem">
          We may gather information about you from third-parties. For instance,
          we allow users to log-in through a Google account. If you log-in
          through Google, we may collect certain information that you authorize
          us to receive from Google such as your name, email address, among
          other information.
        </p>

        <h2>Other information gathered:</h2>
        <p style="margin-bottom: 2rem">
          We use tracking information like Google Analytics and Facebook Pixel,
          both of which use certain tracking tools like cookies to collect
          information about user behavior that help us run the Platform. This
          information may include, but is not limited to, browser type,
          operating system, access time, entrance and/or exit pages, and page
          views. We may use this information to make decisions about what
          content or advertisements to show you. We also may use geolocation
          data from your IP address or mobile phone location data to target
          certain features and services for a user. You can disable location
          sharing on your mobile phone but this may limit some of the features
          on the Platform.
        </p>

        <h2>How HelixPay uses information that we collect:</h2>
        <p style="margin-bottom: 2rem">
          We may use your information for the following reasons;
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          1. To enable or execute certain features on the Platform
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          2. For customer support
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          3. To process billing and payments, which may include sharing
          information with a third-party payment gateway
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          4. For record keeping purposes
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          5. To better understand user experience on the Platform and to make
          changes to features or processes on the Platform
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          6. To send promotional emails or messages to users with offers,
          information, or other promotions
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          7. To monitor for any fraudulent behavior or other prohibited actions
          on the Platform
        </p>
        <p style="width: 90%; margin: auto; text-align: left">
          8. To contact you about certain news from the Platform, for instance
          if a user has outstanding messages or tasks to execute; or to seek
          feedback and/or information from a User or Customer
        </p>
        <p
          style="
            width: 90%;
            margin: auto;
            text-align: left;
            margin-bottom: 2rem;
          "
        >
          9. To resolve any disputes between Users or between a User and the
          Platform; and to enforce any legal terms related to use of the
          Platform
        </p>

        <p style="margin-bottom: 2rem">
          We may combine or aggregate user data together for internal research
          or marketing purposes, resulting in the anonymization of user data. We
          may then share this anonymized data with third-parties including, but
          not limited to, advertisers, sponsors, potential future users, and
          others. We ensure that once combined or aggregated by us, the data
          cannot be likely and reasonably used to single out any individual,
          whether by us or such other third parties. You can notify us if you
          wish to withhold your consent for the anonymization of your user data.
        </p>

        <h2>When HelixPay Discloses Your Information:</h2>
        <p style="margin-bottom: 2rem">
          Under some cases we may share your information that we have collected
          from the HelixPay Platform with third-parties including, but not
          limited to, the following:
        </p>

        <p style="margin-bottom: 2rem">
          <span><i>3rd-Party Service Providers: </i></span>HelixPay uses, and
          may use in the future, data from the Platform for functions such as
          advertising, marketing, billing, including payment gateways, legal
          services, data storage, data analysis, and fraud protection. We may
          share with these service providers such user information that will
          allow them to perform the services HelixPay has engaged them to
          render. We require that these service providers agree to only use this
          user information for the specific service functions provided to
          HelixPay and that they will not use this information for any other
          purposes.
        </p>
        <p style="margin-bottom: 2rem">
          <span><i>Reasons: </i></span>If required by law or if asked by legal
          authorities to disclose certain information for a legal investigation,
          we may provide user information to the relevant authorities.
        </p>
        <p style="margin-bottom: 2rem">
          <span><i>Business Transfer: </i></span>In the future, HelixPay may
          sell part or all of its business.In such a transaction, user
          information may be transferred to the new owner to allow the new owner
          to continue operating the business.
        </p>
        <p style="margin-bottom: 2rem">
          <span><i>Academics & Research: </i></span>HelixPay may provide
          anonymized user information for academic research purposes after
          ascertaining the legitimacy of the academic research.
        </p>

        <h2>Online Analytics</h2>
        <p style="margin-bottom: 2rem">
          HelixPay uses third-party marketing services like Google Analytics,
          Facebook, and others for our Platform marketing initiatives. These
          services use cookies and other tracking tools on your computer and
          mobile phone to collect information about user activity on the
          Platform.
        </p>

        <h2>Privacy of Minors</h2>
        <p style="margin-bottom: 2rem">
          The HelixPay Platform is designed for adults above the age of 18 years
          old and the Platform is not meant to be used by minors under the age
          of 18.
        </p>

        <h2>Security</h2>
        <p style="margin-bottom: 2rem">
          HelixPay uses appropriate measures to protect the personal information
          of our users. However, no security policies are 100% secure and it is
          possible that we could have a data breach of our collected user
          information. We will immediately notify you of any breach in your
          personal data under our control. However, we do not promise and cannot
          guarantee that personal data collected by HelixPay will not be seen by
          third-parties. Users should take precautions to keep their HelixPay
          profile, password, and computer access safe and secure. HelixPay is
          not liable for any unauthorized use of your account on the Platform.
        </p>

        <h2>Links to External Websites and Applications</h2>
        <p style="margin-bottom: 2rem">
          HelixPay may contain links to other websites and applications which
          HelixPay has no control over. These links do not represent an
          endorsement by HelixPay for these websites or the services offered on
          these sites. We provide these links as a convenience for users but
          HelixPay is not responsible for the content of these third-party sites
          and HelixPay is not responsible for the privacy of user information
          collected on, by, or from these third-party sites.
        </p>

        <h2>Updating and Deleting Your Information</h2>
        <p style="margin-bottom: 2rem">
          You can review, edit, and delete your user information on the HelixPay
          Platform. Upon request, we can close your account and remove your
          profile from public view on the Platform. We will generally delete all
          user information, including biographical data, in closed accounts but
          we may retain information from such accounts that we might need to
          resolve any disputes that may be outstanding or could come up in the
          future. This information may include transaction information. Once
          user data becomes inaccurate or incomplete, it must be rectified,
          supplemented, destroyed, or their further processing restricted.
          Notwithstanding the foregoing, you shall have the right to withdraw or
          order the blocking, removal or destruction, of your personal data by
          closing your account.
        </p>

        <h2>Changes to this Policy</h2>
        <p style="margin-bottom: 2rem">
          THIS POLICY IS CURRENT AS OF THE EFFECTIVE DATE POSTED AT THE TOP OF
          THIS DOCUMENT. IN ITS SOLE DISCRETION, HELIXPAY MAY CHANGE THIS POLICY
          AT ANY TIME. IF CHANGED, HELIXPAY WILL POST ITS UPDATED POLICY ON THE
          Platform AND NOTIFY USERS OF THE CHANGE. HELIXPAY ENCOURAGES USERS TO
          REVIEW THIS POLICY REGULARLY FOR ANY CHANGES.
        </p>

        <h2>Contact Us:</h2>
        <p style="margin-bottom: 4rem">
          If you have any questions about the Privacy Policy or the Platform,
          please contact us by sending an email to our data privacy officer,
          Andrew Koger at andrew@helixpay.ph.
        </p>
      </div>

      <MainFooter />
    </div>
  </div>
</template>

<script>
import MainFooter from '@/components/MainFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'PrivacyPolicy',

  components: {
    MainFooter,
  },

  setup() {
    useHead(
      buildPageMeta({
        title:
          'Privacy Policy | Ecommerce Technology for Creators & Communities',
        description:
          'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
        url: 'https://www.helixpay.ph/privacy-policy',
        image: 'https://www.helixpay.ph/img/helixpay_ticketing.jpg',
      })
    );
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  line-height: 2;
  box-sizing: border-box;
}

body {
  font-size: 1.1rem;
  line-height: 2;
}

.main-page {
  width: 100%;
}

h2 {
  font-weight: 600;
  margin-bottom: 1rem;
}

.main-page-info {
  max-width: 60rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  line-height: 1.5;
}

.main-page-info img {
  width: 15rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

span {
  font-weight: 600;
}

p {
  opacity: 0.6;
  font-weight: 500;
}

@media only screen and (max-width: 1000px) {
  .main-page-info {
    font-weight: 300;
    font-size: 1rem;
  }
}
</style>
